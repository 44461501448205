
import Vue from 'vue';
import Component from 'vue-class-component';
import ListDocumentComponent from './ListDocumentComponent.vue';
@Component({
  name: 'ListDocumentContainer',
  components: { ListDocumentComponent }
})
export default class ListDocumentContainer extends Vue {
  //DATA
  switchListType = '0';
  //GETTERS
  get fields() {
    return [
      { field: 'number', label: `#`, sortable: true, filterOptions: { enabled: true, filterDropdownItems: ['2'] } },
      { field: 'typeOrder', label: this.translateCol('type'), sortable: true },
      { field: 'concept', label: this.translateCol('concept'), sortable: true },
      { field: 'date', label: this.translateCol('date'), sortable: true },
      { field: 'customerName', label: this.translateCol('entity'), sortable: true },
      { field: 'warehouseName', label: this.translateCol('warehouse'), sortable: true },
      { field: 'vendor', label: this.translateCol('vendor'), sortable: true },
      { field: 'total', label: this.translateCol('total'), sortable: true },
      { field: 'tax', label: this.translateCol('taxes'), sortable: true },
      { field: 'notes', label: this.translateCol('notes'), sortable: true },
      {
        field: 'approved',
        label: this.translateCol('approved'),
        sortable: true,
        formatFn: (value: boolean) => (value ? `${this.$t('general.yes')}` : `${this.$t('general.no')}`)
      },
      { field: 'currency', label: this.translateCol('currency'), sortable: true }
    ];
  }

  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }
}
